import type { $Fetch } from "ofetch";
import type { DefaultPaginationParams } from "../repository";
import Echo from 'laravel-echo';

import {
  AuthRepository,
  UserRepository,
  ConfigRepository,
  NotificationRepository,
  TournamentRepository,
  FieldRepository,
  OrganisationRepository,
  OrganisationMemberRepository
} from "./repositories";

export type SearchType = "accounts" | "hashtags" | "outfits";

export interface SearchParams extends DefaultPaginationParams {
  /** Attempt WebFinger lookup. Defaults to false. */
  readonly q: string;
  /** Enum(accounts, hashtags, outfits) */
  readonly type?: SearchType | null;
  /** If provided, outfits returned will be authored only by this account */
  readonly accountId?: string | null;
}

export class AggregateRepository {
  readonly auth: AuthRepository;
  readonly user: UserRepository;
  readonly config: ConfigRepository;
  readonly organisation: OrganisationRepository;
  readonly organisationMember: OrganisationMemberRepository;
  readonly notifications: NotificationRepository;
  readonly tournament: TournamentRepository;
  readonly field: FieldRepository;

  constructor(
    private readonly fetch: $Fetch,
    private readonly echo: Echo,
  ) {
    this.auth = new AuthRepository(fetch);
    this.user = new UserRepository(fetch, echo);
    this.config = new ConfigRepository(fetch);
    this.organisation = new OrganisationRepository(fetch);
    this.organisationMember = new OrganisationMemberRepository(fetch);
    this.notifications = new NotificationRepository(fetch, echo);
    this.field = new FieldRepository(fetch);
    this.tournament = new TournamentRepository(fetch);
  }
}
