import type { $Fetch } from "ofetch";
import Echo from 'laravel-echo';

export class NotificationRepository {
  constructor(private readonly fetch: $Fetch, private readonly echo: Echo) { }

  /**
   * Subscribe to the global channel to listen for notifications.
   * This should be called to start listening to notifications.
   */
  subscribeToGlobalChannel(callback: (event: any) => void) {
    console.log('Subscribing to global channel', this.echo);
    this.echo.channel('global')
      .listen('.GlobalNotification', (event: any) => {
        console.log('Received raw global notification:', event); // 
        callback(event);
      });
  }
}
