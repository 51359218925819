import revive_payload_client_bIYRmu7343 from "/opt/buildhome/repo/node_modules/.pnpm/nuxt@3.13.2_eslint@9.10.0_rollup@4.22.0_typescript@5.6.2_vite@5.4.6/node_modules/nuxt/dist/app/plugins/revive-payload.client.js";
import unhead_wBfDkJZKCH from "/opt/buildhome/repo/node_modules/.pnpm/nuxt@3.13.2_eslint@9.10.0_rollup@4.22.0_typescript@5.6.2_vite@5.4.6/node_modules/nuxt/dist/head/runtime/plugins/unhead.js";
import router_cruHjqJQcT from "/opt/buildhome/repo/node_modules/.pnpm/nuxt@3.13.2_eslint@9.10.0_rollup@4.22.0_typescript@5.6.2_vite@5.4.6/node_modules/nuxt/dist/pages/runtime/plugins/router.js";
import _0_siteConfig_334KDI2Twg from "/opt/buildhome/repo/node_modules/.pnpm/nuxt-site-config@2.2.18_rollup@4.22.0_vite@5.4.6_vue@3.5.6/node_modules/nuxt-site-config/dist/runtime/nuxt/plugins/0.siteConfig.js";
import payload_client_uUeWEQWfmk from "/opt/buildhome/repo/node_modules/.pnpm/nuxt@3.13.2_eslint@9.10.0_rollup@4.22.0_typescript@5.6.2_vite@5.4.6/node_modules/nuxt/dist/app/plugins/payload.client.js";
import navigation_repaint_client_VQxlb7bctu from "/opt/buildhome/repo/node_modules/.pnpm/nuxt@3.13.2_eslint@9.10.0_rollup@4.22.0_typescript@5.6.2_vite@5.4.6/node_modules/nuxt/dist/app/plugins/navigation-repaint.client.js";
import check_outdated_build_client_7cdylA9VV0 from "/opt/buildhome/repo/node_modules/.pnpm/nuxt@3.13.2_eslint@9.10.0_rollup@4.22.0_typescript@5.6.2_vite@5.4.6/node_modules/nuxt/dist/app/plugins/check-outdated-build.client.js";
import chunk_reload_client_EqBJtiuZrF from "/opt/buildhome/repo/node_modules/.pnpm/nuxt@3.13.2_eslint@9.10.0_rollup@4.22.0_typescript@5.6.2_vite@5.4.6/node_modules/nuxt/dist/app/plugins/chunk-reload.client.js";
import plugin_vue3_rORuo7TU1m from "/opt/buildhome/repo/node_modules/.pnpm/@pinia+nuxt@0.5.4_rollup@4.22.0_typescript@5.6.2_vue@3.5.6/node_modules/@pinia/nuxt/dist/runtime/plugin.vue3.js";
import components_plugin_KR1HBZs4kY from "/opt/buildhome/repo/.nuxt/components.plugin.mjs";
import prefetch_client_uYSGlhxG54 from "/opt/buildhome/repo/node_modules/.pnpm/nuxt@3.13.2_eslint@9.10.0_rollup@4.22.0_typescript@5.6.2_vite@5.4.6/node_modules/nuxt/dist/pages/runtime/plugins/prefetch.client.js";
import switch_locale_path_ssr_Vh4TXQfUh5 from "/opt/buildhome/repo/node_modules/.pnpm/@nuxtjs+i18n@8.5.2_rollup@4.22.0_vue@3.5.6/node_modules/@nuxtjs/i18n/dist/runtime/plugins/switch-locale-path-ssr.js";
import i18n_Sg21RpaBwq from "/opt/buildhome/repo/node_modules/.pnpm/@nuxtjs+i18n@8.5.2_rollup@4.22.0_vue@3.5.6/node_modules/@nuxtjs/i18n/dist/runtime/plugins/i18n.js";
import slideovers_pmzEXAL3Vr from "/opt/buildhome/repo/node_modules/.pnpm/@nuxt+ui@2.18.5_focus-trap@7.6.0_qrcode@1.5.4_rollup@4.22.0_vite@5.4.6_vue@3.5.6/node_modules/@nuxt/ui/dist/runtime/plugins/slideovers.js";
import modals_GynQhVVHvI from "/opt/buildhome/repo/node_modules/.pnpm/@nuxt+ui@2.18.5_focus-trap@7.6.0_qrcode@1.5.4_rollup@4.22.0_vite@5.4.6_vue@3.5.6/node_modules/@nuxt/ui/dist/runtime/plugins/modals.js";
import colors_K0nDI5of0S from "/opt/buildhome/repo/node_modules/.pnpm/@nuxt+ui@2.18.5_focus-trap@7.6.0_qrcode@1.5.4_rollup@4.22.0_vite@5.4.6_vue@3.5.6/node_modules/@nuxt/ui/dist/runtime/plugins/colors.js";
import plugin_client_HMl5yyh7sY from "/opt/buildhome/repo/node_modules/.pnpm/@nuxtjs+color-mode@3.5.1_rollup@4.22.0/node_modules/@nuxtjs/color-mode/dist/runtime/plugin.client.js";
import plugin_xi3fU4yz2I from "/opt/buildhome/repo/node_modules/.pnpm/@nuxt+icon@1.5.1_rollup@4.22.0_vite@5.4.6_vue@3.5.6/node_modules/@nuxt/icon/dist/runtime/plugin.js";
import scrollbars_client_KeAcKKEpwt from "/opt/buildhome/repo/node_modules/.pnpm/@nuxt+ui-pro@1.4.2_focus-trap@7.6.0_qrcode@1.5.4_rollup@4.22.0_vite@5.4.6_vue@3.5.6/node_modules/@nuxt/ui-pro/modules/pro/runtime/plugins/scrollbars.client.ts";
import presets_7DNRrm2rnx from "/opt/buildhome/repo/node_modules/.pnpm/@nuxt+ui-pro@1.4.2_focus-trap@7.6.0_qrcode@1.5.4_rollup@4.22.0_vite@5.4.6_vue@3.5.6/node_modules/@nuxt/ui-pro/plugins/presets.ts";
import variables_VO8cyhHDIB from "/opt/buildhome/repo/node_modules/.pnpm/@nuxt+ui-pro@1.4.2_focus-trap@7.6.0_qrcode@1.5.4_rollup@4.22.0_vite@5.4.6_vue@3.5.6/node_modules/@nuxt/ui-pro/plugins/variables.ts";
import client_8y0XQRGs9x from "/opt/buildhome/repo/src/api/plugins/client.ts";
import echo_HksZXjvotB from "/opt/buildhome/repo/src/api/plugins/echo.ts";
import _1_scroll_to_top_WX31OCL1jk from "/opt/buildhome/repo/src/plugins/1.scroll-to-top.ts";
import hydration_client_7uir9Lpz2B from "/opt/buildhome/repo/src/plugins/hydration.client.ts";
import sentry_client_config_cPKJD40CT4 from "/opt/buildhome/repo/src/plugins/sentry.client.config.ts";
import sonner_client_cxteQUnCKH from "/opt/buildhome/repo/src/plugins/sonner.client.ts";
import vue_query_JC9gnf7CA1 from "/opt/buildhome/repo/src/plugins/vue-query.ts";
export default [
  revive_payload_client_bIYRmu7343,
  unhead_wBfDkJZKCH,
  router_cruHjqJQcT,
  _0_siteConfig_334KDI2Twg,
  payload_client_uUeWEQWfmk,
  navigation_repaint_client_VQxlb7bctu,
  check_outdated_build_client_7cdylA9VV0,
  chunk_reload_client_EqBJtiuZrF,
  plugin_vue3_rORuo7TU1m,
  components_plugin_KR1HBZs4kY,
  prefetch_client_uYSGlhxG54,
  switch_locale_path_ssr_Vh4TXQfUh5,
  i18n_Sg21RpaBwq,
  slideovers_pmzEXAL3Vr,
  modals_GynQhVVHvI,
  colors_K0nDI5of0S,
  plugin_client_HMl5yyh7sY,
  plugin_xi3fU4yz2I,
  scrollbars_client_KeAcKKEpwt,
  presets_7DNRrm2rnx,
  variables_VO8cyhHDIB,
  client_8y0XQRGs9x,
  echo_HksZXjvotB,
  _1_scroll_to_top_WX31OCL1jk,
  hydration_client_7uir9Lpz2B,
  sentry_client_config_cPKJD40CT4,
  sonner_client_cxteQUnCKH,
  vue_query_JC9gnf7CA1
]