import validate from "/opt/buildhome/repo/node_modules/.pnpm/nuxt@3.13.2_eslint@9.10.0_rollup@4.22.0_typescript@5.6.2_vite@5.4.6/node_modules/nuxt/dist/pages/runtime/validate.js";
import manifest_45route_45rule from "/opt/buildhome/repo/node_modules/.pnpm/nuxt@3.13.2_eslint@9.10.0_rollup@4.22.0_typescript@5.6.2_vite@5.4.6/node_modules/nuxt/dist/app/middleware/manifest-route-rule.js";
export const globalMiddleware = [
  validate,
  manifest_45route_45rule
]
export const namedMiddleware = {
  auth: () => import("/opt/buildhome/repo/src/middleware/auth.ts"),
  guest: () => import("/opt/buildhome/repo/src/middleware/guest.ts"),
  organisation: () => import("/opt/buildhome/repo/src/middleware/organisation.ts"),
  unverified: () => import("/opt/buildhome/repo/src/middleware/unverified.ts"),
  verified: () => import("/opt/buildhome/repo/src/middleware/verified.ts"),
  verify: () => import("/opt/buildhome/repo/src/middleware/verify.ts")
}